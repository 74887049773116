import { useEffect } from 'react';
import { navigate } from "@reach/router"

export default () => {
  useEffect(() => {
    navigate('https://www.inkchievous.com/');
  }, []);
  return null;
};

// import React from "react"
// import { Link } from "gatsby"
// import Image404 from "../../src/adventurebyte/components/404-image"

// import Layout from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

// const NotFound = () => {
//   return (
//     <Layout>
//       <Link to="/">
//         <Image404 />
//       </Link>
//     </Layout>
//   )
// }

// export default NotFound